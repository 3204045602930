import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js"

/**
 * DjSchool Progress Controller
 * 
 * This controller manages video lesson playback and progress tracking.
 * 
 * Global API:
 * window.djschool.endLesson()
 * - Ends the current lesson
 * - Triggers the 'lessonEnd' event with completion status
 * - Cleans up event listeners and resets the video state
 * - Can be called externally to manually end a lesson (e.g., when closing the view)
 * 
 * Example usage:
 * window.addEventListener('lessonEnd', (e) => console.log('Lesson ended:', e.detail));
 * window.djschool.endLesson();
 */

const MIN_COMPLETION_PERCENTAGE = 90

// Connects to data-controller="djschool-progress"
export default class extends Controller {
  static values = {
    apiPath: String,
    iconId: String,
    iconPlayedPath: String,
    iconUnplayedPath: String,
    iconCurrentPath: String,
    status: String,
    courseId: String,
    courseName: String,
    lessonId: String,
    lessonName: String,
  }

  initialize() {
    this.resetVideoState()
    this.totalDuration = 0
  }

  connect() {
    this.handleVideoEnded = this.handleVideoEnded.bind(this);
    this.handleVideoPlaying = this.handleVideoPlaying.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.element.addEventListener("playing", this.handleVideoPlaying);
    this.element.addEventListener("timeupdate", this.handleTimeUpdate);

    // Make the controller globally accessible
    window.djschool = {
      endLesson: () => this.endLesson()
    };

    this.element.play().catch(() => {});
    this.initialStatus = this.statusValue;
    this.hasTriggeredEnd = false;
  }

  disconnect() {
    this.endLesson();
    // Clean up global reference
    window.djschool = null;
  }

  // Public method to end the lesson
  endLesson() {
    this.element.pause(); // stop playing video
    this.element.removeEventListener("playing", this.handleVideoPlaying);
    this.element.removeEventListener("timeupdate", this.handleTimeUpdate);
    if (this.statusValue === "playing") {
      this.setIcon(this.initialStatus);
    }
    this.handleVideoEnded();
  }

  setIcon(status) {
    const iconElement = document.getElementById(this.iconIdValue);
    if (status === "played") {
      iconElement.src = this.iconPlayedPathValue;
    } else if (status === "playing") {
      iconElement.src = this.iconCurrentPathValue;
    } else if (status === "unplayed") {
      iconElement.src = this.iconUnplayedPathValue;
    }
  }

  statusValueChanged() {
    this.setIcon(this.statusValue);
  }

  isVideoCompleted() {
    return (this.maxWatchedTime / this.totalDuration) * 100 >= MIN_COMPLETION_PERCENTAGE;
  }

  handleTimeUpdate() {
    const currentTime = this.element.currentTime;
    this.totalDuration = this.element.duration;
    this.maxWatchedTime = Math.max(this.maxWatchedTime, currentTime);

    // Check if we've reached completion threshold and haven't triggered the end yet
    if (!this.hasTriggeredEnd && this.isVideoCompleted()) {
      this.hasTriggeredEnd = true;
      this.handleVideoEnded();
    }
  }

  createLessonEvent(type, completed = null) {
    const eventData = {
      event: type,
      courseId: this.courseIdValue,
      courseName: this.courseNameValue,
      lessonId: this.lessonIdValue,
      lessonName: this.lessonNameValue
    };

    if (completed !== null) {
      eventData.completed = completed;
    }

    return eventData;
  }

  dispatchLessonEvent(type, data) {
    const event = new CustomEvent(type, {
      detail: data,
      bubbles: true
    });
    window.dispatchEvent(event)
  }

  handleVideoPlaying() {
    if (!this.hasStarted) {
      this.statusValue = "playing";
      const eventData = this.createLessonEvent("lessonStart");
      this.dispatchLessonEvent("lessonStart", eventData);
      this.hasStarted = true;
    }
  }

  resetVideoState() {
    this.hasStarted = false;
    this.maxWatchedTime = 0;
    this.hasTriggeredEnd = false;
  }

  // will be called when played duration >= 90%
  async handleVideoEnded() {
    if (this.hasStarted) {
      const completed = this.isVideoCompleted();
      if (completed) {
        this.statusValue = "played";
        await post(this.apiPathValue, {});
      }

      const eventData = this.createLessonEvent("lessonEnd", completed);
      this.dispatchLessonEvent("lessonEnd", eventData);
      this.resetVideoState();
    }
  }
}
